import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./press.module.css"

import Container from "../components/container.js"

const Press = () => {
	
	const pressData = useStaticQuery(graphql`
		query pressImages {
			mercury: file (relativePath: {eq: "mercury_screencap.png"}) {
				id
				childImageSharp {
					fixed(height: 208) {
						...GatsbyImageSharpFixed
					}
				}
			}
			register: file(relativePath: {eq: "register_guard_screencap.png"}) {
    		id
    		childImageSharp {
      		fixed (height: 208){
      			...GatsbyImageSharpFixed
      		}
    		}
  		}
			corvallisGazette: file(relativePath: {eq: "corvallis_gazette_screencap.png"}) {
    		id
    		childImageSharp {
      		fixed (height: 208){
      			...GatsbyImageSharpFixed
      		}
    		}
  		}
  		dailyEmerald: file(relativePath: {eq: "daily_emerald_screencap.png"}) {
    		id
    		childImageSharp {
      		fixed (height: 208){
      			...GatsbyImageSharpFixed
      		}
    		}
  		}
  		kezi: file(relativePath: {eq: "kezi_screencap.png"}) {
    		id
    		childImageSharp {
      		fixed (height: 208){
      			...GatsbyImageSharpFixed
      		}
    		}
  		}
  		kmtr: file(relativePath: {eq: "kmtr_screencap.png"}) {
    		id
    		childImageSharp {
      		fixed (height: 208){
      			...GatsbyImageSharpFixed
      		}
    		}
  		}
		}
	`)
	
	return (
		<div>
			<Container>
				<div className={styles.bump}></div>
				<h1>
					Press Coverage
				</h1>
				<p>
					We need help getting the word out and raising support from the larger community. If you are a journalist — or interested community member — seeking more information, please don't hesistate to contact us <Link to="/contact/"><strong>here</strong></Link>!
				</p>
				
				{//Begin the list
				}
				<hr/>
				<a href="https://www.portlandmercury.com/blogtown/2020/06/30/28591093/portland-market-of-choice-employees-sent-home-for-wearing-black-lives-matter-pins">
					<div className={styles.entry}>
						<div className="entryText">
							<h2> Portland Mercury </h2>
							<p className={styles.cap}> Portland Market of Choice Employees Sent Home for Wearing Black Lives Matter Pins 
							
							<div className={styles.image}>
								<Img fixed={pressData.mercury.childImageSharp.fixed}/>
							</div>
							</p>
							<p> Published June 30th, 2020 </p>
						
						</div>
						
					</div>
				</a>
				
				
				
				
				<hr/>
				<a href="https://www.registerguard.com/news/20200626/forbidden-from-wearing-black-lives-matter-masks-some-market-of-choice-employees-walk-out">
					<div className={styles.entry}>
						<div className="entryText">
							<h2> The Register Guard </h2>
							<p className={styles.cap}>Forbidden from wearing Black Lives Matter masks, some Market of Choice employees walk out</p>
							<p> Published June 26th, 2020 </p>
						</div>
						<div className={styles.image}>
								<Img fixed={pressData.register.childImageSharp.fixed}/>
						</div>
					</div>
				</a>
				
				
				
				<hr/>
				<a href="https://www.gazettetimes.com/news/local/market-of-choice-employees-balk-at-companys-stance-on-black-lives-matter-masks/article_3c416908-1894-5ecb-9847-56d6af31ea69.html">
					<div className={styles.entry}>
						<div className="entryText">
							<h2> Corvallis Gazette Times </h2>
							<p className={styles.cap}> Market of Choice employees balk at company's stance on Black Lives Matter masks </p>
							<p> Published July 1st, 2020 </p>
						</div>
						<div className={styles.image}>
								<Img fixed={pressData.corvallisGazette.childImageSharp.fixed}/>
						</div>
					</div>
				</a>
				
				
				
				<hr/>
				<a href="https://www.dailyemerald.com/news/market-of-hypocrisy-market-of-choice-employees-protest-policies-forbidding-black-lives-matter-masks/article_4860e002-ba87-11ea-8dd5-f7d4f103e355.html">
					<div className={styles.entry}>
						<div className="entryText">
							<h2> Daily Emerald </h2>
							<p className={styles.cap}> ‘Market of Hypocrisy’: Market of Choice employees protest policies forbidding Black Lives Matter masks </p>
							<p> Published June 29th, 2020 </p>
						</div>
						<div className={styles.image}>
								<Img fixed={pressData.dailyEmerald.childImageSharp.fixed}/>
						</div>
					</div>
				</a>
				
				
				<hr/>
				<a href="https://www.kezi.com/content/news/Employees-walk-off-the-job-at-Market-of-Choice-stores-across-the-state--571496731.html">
					<div className={styles.entry}>
						<div className="entryText">
							<h2> KEZI 9 </h2>
							<p className={styles.cap}> Market of Choice Employees Walk Off the Job over BLM Mask Controversy </p>
							<p> Published June 26th, 2020 </p>
						</div>
						<div className={styles.image}>
								<Img fixed={pressData.kezi.childImageSharp.fixed}/>
						</div>
					</div>
				</a>
				
				
				
				<hr/>
				<a href="https://nbc16.com/news/local/market-of-choice-employees-walk-out-over-ban-of-blm-face-masks">
					<div className={styles.entry}>
						<div className="entryText">
							<h2> KMTR 16 </h2>
							<p className={styles.cap}> Market of Choice employees walk out over ban of BLM face masks </p>
							<p> Published June 25th, 2020 </p>
						</div>
						<div className={styles.image}>
								<Img fixed={pressData.kmtr.childImageSharp.fixed}/>
						</div>
					</div>
				</a>
				
				
			</Container>
		</div>
	)
}

export default Press
